import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "components/Button"
import Container from "components/Container"
import Icon from "components/Icon"
import Page from "components/Page"
import Link from "components/Link"
import { getParams } from "utils/form"

import styles from "./index.module.scss"

const Home = ({ data }) => {
  const [status, setStatus] = useState("idle") // idle, subscribing, subscribed, failed
  const [error, setError] = useState("")
  const { edges } = data.allMarkdownRemark

  const handleSubmitEmail = e => {
    e.preventDefault()

    setStatus("subscribing")
    setError("")

    const { email, name } = getParams(e.target)

    fetch(`${process.env.GATSBY_API_URL}/subscribe-to-email-course`, {
      method: "POST",
      body: JSON.stringify({ email, name }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async res => {
        if (!res.ok) {
          const { message } = await res.json()
          throw Error(message)
        }

        setStatus("subscribed")
      })
      .catch(err => {
        setError(err.message || "Something went wrong. Try again later.")
        setStatus("idle")
      })
  }

  return (
    <Page showFooterCTA={false}>
      <Container containerClassName={styles.container} className={styles.hero}>
        <div className={styles.heroCopy}>
          <h1>
            Help teams <br /> switch remote
          </h1>
          <p>
            Learn how to be effective as a remote team. Increase productivity,
            build happiness, avoid the pitfalls. <b>We are here to help you.</b>
          </p>
          <Link as="button" variant="cta" size="large" to="/contact-us">
            Get Started
          </Link>
        </div>

        <div className={styles.heroGraph}>
          <img src="/landing-hero.png" alt="" />
        </div>
      </Container>

      <Container
        containerClassName={styles.missionContainer}
        className={styles.mission}
      >
        <p className={styles.label}>Why do it?</p>
        <h2 className={styles.sectionTitle}>Mission</h2>

        <p className={styles.missionStatement}>
          Our mission is to enable people to work in their preferred way -{" "}
          <b>in office</b> or <b>remote</b>. And to help them do it in an
          efficient way, avoiding most pitfalls that new remote teams encounter.
        </p>

        <h2 className={styles.sectionTitle} style={{ marginTop: 40 }}>
          Core Values
        </h2>

        <ul className={styles.values}>
          <li>
            <Icon name="char-r" width={80} height={100} />
            <h4>Remote</h4>
            <p className={styles.text}>Allowing remote work empowers people.</p>
          </li>

          <li>
            <Icon name="char-e" width={80} height={100} />
            <h4>Efficiency</h4>
            <p className={styles.text}>
              Instead of long hours, strive towards efficiency.
            </p>
          </li>

          <li>
            <Icon name="char-m" width={80} height={100} />
            <h4>Motivation</h4>
            <p className={styles.text}>
              Be motivated to be the best version of yourself.
            </p>
          </li>

          <li>
            <Icon name="char-o" width={80} height={100} />
            <h4>Ownership</h4>
            <p className={styles.text}>
              Embrace responsibility and help others in everything.
            </p>
          </li>

          <li>
            <Icon name="char-t" width={80} height={100} />
            <h4>Trust</h4>
            <p className={styles.text}>
              Don't micro-manage people, trust them to do their best work.
            </p>
          </li>
        </ul>
      </Container>

      <Container
        id="email-course"
        containerClassName={styles.courseContainer}
        className={styles.course}
      >
        <p className={styles.label}>Where to start?</p>
        <h2 className={styles.sectionTitle}>Email Course</h2>

        <p className={styles.text}>
          Every new beginning is hard. Join our 7-parts email course
          <br />
          for beginners to learn how to be most productive.
        </p>

        {status !== "subscribed" && (
          <>
            <form className={styles.courseInput} onSubmit={handleSubmitEmail}>
              <input
                required
                type="email"
                name="email"
                placeholder="Email address"
              />
              <input
                required
                type="text"
                name="name"
                placeholder="First Name"
              />
              <Button.Secondary
                disabled={status === "subscribing"}
                loading={status === "subscribing"}
                type="submit"
              >
                Start learning
              </Button.Secondary>
            </form>

            {error && <p className={styles.courseFail}>{error}</p>}
          </>
        )}

        {status === "subscribed" && (
          <div className={styles.courseInput}>
            <p className={styles.courseSuccess}>
              You were successfully subscribed. We will contact you soon.
            </p>
          </div>
        )}
      </Container>

      <Container
        containerClassName={styles.articlesContainer}
        className={styles.articles}
      >
        <p className={styles.label}>Want to get deeper?</p>
        <h2 className={styles.sectionTitle}>Articles</h2>

        <ul className={styles.articlesList}>
          {edges.map((edge, index) => {
            const {
              title,
              path,
              excerpt,
              featuredImage: {
                childImageSharp: { fluid },
              },
            } = edge.node.frontmatter

            return (
              <li key={edge.node.id} className={styles.article}>
                <header>
                  {fluid && <Img fluid={fluid} className={styles.articleImg} />}
                </header>

                <section>
                  <div>
                    <Link.BorderUnderlineHover
                      to={path}
                      className={styles.articleTitle}
                    >
                      {title}
                    </Link.BorderUnderlineHover>
                    <p className={styles.articleExcerpt}>{excerpt}</p>
                  </div>
                </section>
              </li>
            )
          })}
        </ul>

        <Link.Underline to="/blog">Read more articles</Link.Underline>
      </Container>
    </Page>
  )
}

export const query = graphql`
  query landingQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            tags
            excerpt
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Home
